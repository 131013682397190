<!--扣费详情-->
<template>
  <div class="detail">
    <!-- 普通直播 -->
    <template v-if="$route.query.type == 6">
      <pagination2 :option="post1" url="/Wallet/deduction" key="6">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">-{{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>
                  合计上课时长：{{ info.duration | formatTimeLength }}
                </span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 1 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="7">扣费类型：直播课-普通模式</el-col>
              <el-col :span="7">
                <!-- 普通模式扣费详情 -->
                <span>合计峰值人数(人)：{{ info.number }}</span>
              </el-col>
            </el-row>
          </div>

          <div class="tird_container">
            <div class="option">
              <el-input
                size="medium"
                :placeholder="
                  post1.type == 1
                    ? '输入课程名称搜索'
                    : post1.type == 2
                    ? '输入章节名称搜索'
                    : '输入老师名称搜索'
                "
                v-model="post1.search"
                class="input-with-select"
              ></el-input>
            </div>

            <div class="input">
              <el-select
                v-model="post1.type"
                class="select"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div style="clear: both"></div>

            <el-table
              @sort-change="change3"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column label="课程" prop="course_name" />

              <el-table-column label="章节" prop="schedule_name" />

              <el-table-column
                label="上课老师"
                prop="teacher_name"
              ></el-table-column>

              <el-table-column
                label="开始上课时间"
                sortable="custom"
                min-width="130px"
                prop="s_time"
              >
                <template slot-scope="{ row }">
                  {{ row.s_time | formatTimeStamp('hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column
                label="最近上课时间"
                min-width="130px"
                prop="m_time"
                sortable="custom"
              >
                <template slot-scope="{ row }">
                  {{ row.m_time | formatTimeStamp('hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column
                label="峰值人数"
                prop="number"
                min-width="150px"
                sortable="custom"
              >
                <template slot="header">
                  <div
                    class="flex-center hidetext"
                    style="float: left; margin-top: 5px"
                  >
                    峰值人数
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="指直播间最高同时在线人数"
                    ></helpIcon>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="上课时长">
                <template slot-scope="{ row }">
                  {{ row.duration | formatTimeLength }}
                </template>
              </el-table-column>

              <el-table-column
                label="消耗叮豆（个）"
                min-width="120px"
                prop="amount"
              >
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.amount }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 视频加密11 -->
    <template v-if="$route.query.type == 11">
      <pagination2 :option="post11" url="/Wallet/deduction" key="11">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">-{{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <!-- <span>
                  合计视频时长：{{ info.duration | formatTimeLength }}
                </span> -->
                扣费类型：视频转码
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 6 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="tird_container">
            <div style="clear: both"></div>

            <el-table
              @sort-change="change3"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column label="转码规格" prop="video_spec">
                <template slot-scope="{ row }">
                  <span v-if="row.video_spec == 1">720P</span>
                  <span v-else-if="row.video_spec == 2">1080P</span>
                  <span v-else-if="row.video_spec == 3">360P</span>
                  <span v-else>480P</span>
                </template>
              </el-table-column>

              <el-table-column label="类型" prop="transcode_type">
                <template slot-scope="{ row }">
                  <!-- 1加密转码   2普通转码 3下载转码 -->
                  <span v-if="row.transcode_type == 2">普通转码</span>
                  <span v-if="row.transcode_type == 1">加密转码</span>
                  <span v-if="row.transcode_type == 3">下载转码</span>
                </template>
              </el-table-column>

              <el-table-column label="大小" prop="schedule_name">
                <template slot-scope="{ row }">{{ row.video_size }}MB</template>
              </el-table-column>

              <el-table-column
                label="转码开始/结束时间"
                min-width="120"
                prop="vid"
              >
                <template slot-scope="{ row }">
                  <div>
                    {{ row.s_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                  </div>
                  <div>
                    <span v-if="row.e_time > 0">
                      {{ row.e_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                    </span>
                    <span v-else>-</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="视频时长" prop="s_time">
                <template slot-scope="{ row }">
                  {{ row.video_duration | formatTimeLength }}
                </template>
              </el-table-column>

              <el-table-column
                label="单价(叮豆/分钟)"
                prop="unit_price"
              ></el-table-column>

              <el-table-column label="消耗叮豆(个)">
                <template slot-scope="{ row }">
                  <span class="red">{{ row.price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 极速直播 222-->
    <template v-if="$route.query.type == 7">
      <pagination2 :option="post1" url="/Wallet/deduction" key="7">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">-{{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>
                  合计上课时长：{{ info.duration | formatTimeLength }}
                </span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 2 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">扣费类型：直播课-极速模式</el-col>
              <el-col :span="7">
                <!-- 极速模式扣费详情 -->
                <span>合计连麦峰值人数(人)：{{ info.av_number }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 极速模式扣费详情 -->
                <span>合计旁听人数(人)：{{ info.pt_number }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="tird_container">
            <div class="input_height">
              <div class="option">
                <el-input
                  size="medium"
                  :placeholder="
                    post1.type == 1
                      ? '输入课程名称搜索'
                      : post1.type == 2
                      ? '输入章节名称搜索'
                      : '输入老师名称搜索'
                  "
                  v-model="post1.search"
                  class="input-with-select"
                ></el-input>
              </div>
              <div class="input">
                <el-select
                  v-model="post1.type"
                  class="select"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <el-table
              @sort-change="change"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column label="课程" prop="course_name" />
              <el-table-column label="章节" prop="schedule_name" />
              <el-table-column label="上课老师" prop="teacher_name" />
              <el-table-column
                label="峰值人数"
                min-width="110px"
                sortable="custom"
                prop="im_number"
              >
                <template slot="header">
                  <div
                    class="flex-center hidetext"
                    style="float: left; margin-top: 5px"
                  >
                    峰值人数
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="指直播间最高同时在线人数,包含上课老师"
                    ></helpIcon>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="连麦峰值人数"
                min-width="130px"
                sortable="custom"
                prop="av_number"
              >
                <template slot="header">
                  <div
                    class="flex-center hidetext"
                    style="float: left; margin-top: 5px"
                  >
                    连麦峰值人数
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="指直播间最高同时连麦人数,包含上课老师"
                    ></helpIcon>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="旁听人数"
                min-width="100px"
                prop="pt_number"
              >
                <template slot="header">
                  <div
                    class="flex-center hidetext"
                    style="float: left; margin-top: 5px"
                  >
                    旁听人数
                    <helpIcon
                      style="margin-left: 6px"
                      prompt="旁听人数=峰值人数-连麦峰值人数"
                    ></helpIcon>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="上课时长">
                <template slot-scope="{ row }">
                  {{ row.duration | formatTimeLength }}
                </template>
              </el-table-column>
              <el-table-column
                label="消耗叮豆（个）"
                min-width="120px"
                prop="amount"
              >
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.amount }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 回放 录播 333-->
    <template v-if="$route.query.type == 8 || $route.query.type == 9">
      <pagination2 :option="post2" url="/Wallet/deduction" key="8">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">-{{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <span>合计视频大小(GB)：{{ info.video_size }}</span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 3 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                扣费类型：
                <span v-if="info.type == 8">录播</span>
                <span v-if="info.type == 9">回放</span>
              </el-col>
              <el-col :span="7">
                <!-- 回放扣费详情 和  录播扣费详情-->
                <span>合计学习人数(人)：{{ info.learning_num }}</span>
              </el-col>
              <el-col :span="7"></el-col>
              <el-col :span="3"></el-col>
            </el-row>
          </div>
          <div class="tird_container">
            <div class="option">
              <el-input
                size="medium"
                :placeholder="
                  post2.type == 1
                    ? '输入课程名称搜索'
                    : post2.type == 2
                    ? '输入章节名称搜索'
                    : '输入老师名称搜索'
                "
                v-model="post2.search"
                class="input-with-select"
              ></el-input>
            </div>
            <div class="input">
              <el-select
                v-model="post2.type"
                class="select"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="clear: both"></div>

            <el-table
              @sort-change="change2"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column label="课程" prop="course_name" />
              <el-table-column label="章节" prop="schedule_name" />
              <el-table-column label="上课老师" prop="teacher_name" />
              <!-- 回放特有 -->
              <el-table-column
                v-if="$route.query.type == 9"
                label="回放片段ID"
                prop="video_id"
              />
              <el-table-column
                label="学习人数"
                sortable="custom"
                prop="learning_num"
              />
              <el-table-column
                min-width="160px"
                prop="video_size"
                label="视频文件大小（GB）"
              ></el-table-column>
              <el-table-column
                label="消耗叮豆（个）"
                prop="video_pay_total_price"
              >
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.video_pay_total_price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 小班 444 -->
    <template v-if="$route.query.type == 2">
      <pagination2 :option="post2" url="/Wallet/deduction" key="2">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">- {{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <span>
                  合计上课时长：{{ info.duration | formatTimeLength }}
                </span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 4 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">扣费类型：小班课</el-col>
              <el-col :span="7">
                <span>合计实际上课人数(人)：{{ info.number }}</span>
              </el-col>
            </el-row>
          </div>
          <div class="tird_container">
            <div class="option">
              <el-input
                size="medium"
                :placeholder="
                  post2.type == 1
                    ? '输入课程名称搜索'
                    : post2.type == 2
                    ? '输入章节名称搜索'
                    : '输入老师名称搜索'
                "
                v-model="post2.search"
                class="input-with-select"
              ></el-input>
            </div>
            <div class="select3">
              <el-select
                size="medium"
                style="height: 36px"
                v-model="post2.type"
                class="select"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div style="clear: both"></div>
            <el-table
              @sort-change="change2"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%"
            >
              <el-table-column label="课程" prop="course_name" />
              <el-table-column label="章节" prop="schedule_name" />
              <el-table-column label="上课老师" prop="teacher_name" />
              <el-table-column label="班型" prop="clearing_number">
                <template slot-scope="{ row }">
                  1v{{ row.clearing_number }}
                </template>
              </el-table-column>
              <el-table-column
                prop="number"
                label="实际上课人数"
                min-width="150px"
                sortable="custom"
              ></el-table-column>
              <el-table-column label="上课时长">
                <template slot-scope="{ row }">
                  {{ row.duration | formatTimeLength }}
                </template>
              </el-table-column>
              <el-table-column label="消耗叮豆（个）" prop="amount">
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.amount }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 短信验证 5555 -->
    <template v-if="$route.query.type == 10">
      <pagination2 :option="post3" url="/Wallet/deduction" key="10">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">- {{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>扣费类型：短信验证</span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 5 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="tird_container">
            <div class="input2">
              <el-select
                v-model="post3.type"
                class="select2"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div style="clear: both"></div>
            </div>
            <el-table
              @sort-change="sortchagne"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column
                label="发送时间"
                sortable="custom"
                prop="course_name"
              >
                <template slot-scope="{ row }">
                  {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>
              <el-table-column label="学员" prop="uname">
                <template slot-scope="{ row }">
                  <div class="flex-center">
                    <img
                      width="20"
                      height="20"
                      class="mr10"
                      :src="row.uphoto"
                      alt=""
                    />
                    {{ row.uname }}
                    <span v-if="row.student_remarks">
                      ({{ row.student_remarks }})
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="来源" prop="type">
                <template slot-scope="{ row }">
                  {{ row.type | gettype }}
                </template>
              </el-table-column>
              <el-table-column label="手机号" prop="phone" />
              <el-table-column label="消耗叮豆（个）" prop="amount">
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.price }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row }" v-if="row.uid != 0">
                  <router-link
                    :to="{
                      path: `/manageStudent/detail?uid=${row.uid}`,
                    }"
                  >
                    <el-button type="text" size="small">查看学员</el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 直播-公开课 -->
    <template v-if="$route.query.type == 12 || $route.query.type == 14">
      <pagination2 :option="post4" url="/Wallet/deduction" key="12">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">- {{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>
                  合计上课时长：{{ info.duration | formatTimeLength }}
                </span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 7 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>
                  扣费类型：
                  {{
                    $route.query.type == 12 ? '直播-公开课' : '直播-智能直播'
                  }}
                </span>
              </el-col>
            </el-row>
          </div>

          <div class="tird_container">
            <el-table
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column label="公开课" prop="course_name">
                <template slot-scope="{ row }">
                  {{ row.course_name }}
                </template>
              </el-table-column>

              <el-table-column
                label="上课老师"
                prop="teacher_name"
              ></el-table-column>

              <el-table-column label="开始时间" prop="start_time">
                <template slot-scope="{ row }">
                  {{ row.start_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column label="结束时间" prop="end_time">
                <template slot-scope="{ row }">
                  {{ row.end_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column label="流量(MB)" prop="flux">
                <div slot="header" class="flex-center">
                  流量(MB)
                  <helpIcon
                    style="margin-left: 6px"
                    prompt="流量=所有人观看的直播总时长(只算学员角色)*码率(Mbps) / 8"
                  ></helpIcon>
                </div>
                <template slot-scope="{ row }">{{ row.flux }}</template>
              </el-table-column>

              <el-table-column label="直播类型">
                <template slot-scope="{ row }">
                  {{ row.live_mode | get_live_mode }}
                </template>
              </el-table-column>

              <el-table-column label="消耗叮豆（个）" prop="clearing_price">
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.clearing_price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 公开课回放 -->
    <template v-if="$route.query.type == 13 || $route.query.type == 15">
      <pagination2 :option="post5" url="/Wallet/deduction" key="13">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">- {{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>合计视频大小(GB)：{{ info.video_size }}</span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 3 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>
                  扣费类型：
                  {{ $route.query.type == 13 ? '公开课回放' : '智能直播回放' }}
                </span>
              </el-col>
              <el-col :span="7">
                <span>合计学习人数(人)：{{ info.learning_num }}</span>
              </el-col>
            </el-row>
          </div>

          <div class="tird_container">
            <el-table
              @sort-change="sortchagne12"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column label="公开课" prop="course_name">
                <template slot-scope="{ row }">
                  {{ row.course_name }}
                </template>
              </el-table-column>

              <el-table-column
                label="上课老师"
                prop="teacher_name"
              ></el-table-column>

              <el-table-column label="回放片段" prop="video_id">
                <template slot-scope="{ row }">
                  {{ row.video_id }}
                </template>
              </el-table-column>

              <el-table-column
                label="学习人数"
                prop="learning_num"
                sortable="custom"
              >
                <template slot-scope="{ row }">
                  {{ row.learning_num }}
                </template>
              </el-table-column>

              <el-table-column label="视频大小(GB)" prop="video_size">
                <template slot-scope="{ row }">{{ row.video_size }}</template>
              </el-table-column>

              <el-table-column
                label="消耗叮豆（个）"
                prop="video_pay_total_price"
              >
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.video_pay_total_price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>

    <!-- 连麦互动 -->
    <template v-if="$route.query.type == 16">
      <pagination2 :option="post5" url="/Wallet/deduction" key="13">
        <template v-slot:default="{ tableData: { info = {}, list } }">
          <div class="first_container">
            <el-row>
              <el-col :span="7">
                结算时间：{{
                  info.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                }}
              </el-col>
              <el-col :span="7">
                合计消耗叮豆(个)：
                <span style="color: #ff3535">- {{ info.amount }}</span>
              </el-col>
              <el-col :span="7">
                <span>
                  合计连麦时长：{{ info.duration | formatTimeLength }}
                </span>
              </el-col>
              <el-col :span="3">
                <div
                  class="standard"
                  @click="
                    $router.push({
                      path: '/capitalAccount/TopUpDingDou',
                      query: { type: 7 },
                    })
                  "
                >
                  资费标准
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="7">
                <!-- 其他的 -->
                <span>扣费类型：连麦互动</span>
              </el-col>
              <!-- <el-col :span="7">
                <span>合计学习人数(人)：{{ info.learning_num }}</span>
              </el-col> -->
            </el-row>
          </div>

          <div class="tird_container">
            <el-table
              @sort-change="sortchagne12"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
              :data="list"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column label="公开课" prop="open_class_name">
                <template slot-scope="{ row }">
                  {{ row.open_class_name }}
                </template>
              </el-table-column>

              <el-table-column label="连麦时长" prop="duration">
                <template slot-scope="{ row }">
                  <div>{{ row.duration | formatTimeLength }}</div>
                </template>
              </el-table-column>

              <el-table-column label="连麦开始时间" prop="s_time">
                <template slot-scope="{ row }">
                  {{ row.s_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column label="连麦结束时间" prop="e_time">
                <template slot-scope="{ row }">
                  {{ row.e_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}
                </template>
              </el-table-column>

              <el-table-column label="连麦者" prop="uname">
                <template slot-scope="{ row }">{{ row.uname }}</template>
              </el-table-column>

              <el-table-column label="角色" prop="role">
                <template slot-scope="{ row }">
                  <span v-if="row.role == 1">老师</span>
                  <span v-if="row.role == 2">学生</span>
                </template>
              </el-table-column>

              <el-table-column label="单价" prop="live_call_unit_price">
                <template slot-scope="{ row }">
                  <span>{{ row.live_call_unit_price }}</span>
                </template>
              </el-table-column>

              <el-table-column label="消耗叮豆（个）" prop="live_call_price">
                <template slot-scope="{ row }">
                  <span class="font1">-{{ row.live_call_price }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </pagination2>
    </template>
  </div>
</template>

<script>
import helpIcon from '@/components/helpIcon'
import bg from '../../mixin/background'
export default {
  mixins: [bg],
  data() {
    return {
      picktime: '',

      post11: {
        sr_id: this.$route.query.sr_id,
        type: 1,
        search: '',
      },

      post1: {
        sr_id: this.$route.query.sr_id,
        order: '',
        sort: '',
        type: 1,
        search: '',
      },

      post2: {
        sr_id: this.$route.query.sr_id,
        sort: '',
        type: 1,
        search: '',
      },

      post3: {
        sr_id: this.$route.query.sr_id,
        sort: '',
        type: 0,
      },

      post4: {
        sr_id: this.$route.query.sr_id,
      },

      post5: {
        sr_id: this.$route.query.sr_id,
        sort: '',
      },

      options: [
        {
          value: 1,
          label: '课程',
        },
        {
          value: 2,
          label: '章节',
        },
        {
          value: 3,
          label: '上课老师',
        },
      ],

      options11: [
        {
          value: 1,
          label: '课程',
        },
        {
          value: 2,
          label: '章节',
        },
      ],

      options3: [
        {
          value: 0,
          label: '全部来源',
        },
        {
          value: 1,
          label: '登录网校后',
        },
        {
          value: 2,
          label: '报名课程前',
        },
        {
          value: 3,
          label: '观看直播时',
        },
        {
          value: 4,
          label: '观看录播时',
        },
        {
          value: 5,
          label: '发起/参与拼团时',
        },
        {
          value: 6,
          label: '使用优惠券时',
        },
        {
          value: 7,
          label: '使用兑换码/提取码时 ',
        },
        {
          value: 8,
          label: '直播间发言时',
        },
        {
          value: 9,
          label: '绑定手机号',
        },
        {
          value: 10,
          label: '修改手机号',
        },
        {
          value: 11,
          label: '修改密码 ',
        },
        {
          value: 12,
          label: '登录PC网校',
        },
        {
          value: 13,
          label: '账号保护验证',
        },
        {
          value: 14,
          label: '登录H5网校',
        },
        {
          value: 15,
          label: '信息采集短信验证',
        },
        {
          value: 16,
          label: '推广员激活',
        },
        {
          value: 17,
          label: '个人微信商户绑定',
        },
      ],

      input3: '',
    }
  },

  components: {
    helpIcon,
  },

  filters: {
    get_live_mode(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '标准直播'
          break
        case 2:
          text = '快直播'
          break
        case 3:
          text = '智能直播'
          break
      }
      return text
    },

    get_video_spec(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '720P'
          break
        case 2:
          text = '1080P'
          break
        case 3:
          text = '360P'
          break
        case 4:
          text = '480P'
          break
      }
      return text
    },

    gettype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '登录网校后'
          break
        case 2:
          text = '报名课程时'
          break
        case 3:
          text = '观看直播时'
          break
        case 4:
          text = '观看录播时'
          break
        case 5:
          text = '发起/参与拼团时'
          break
        case 6:
          text = '使用优惠券时'
          break
        case 7:
          text = '使用兑换码/提取码时'
          break
        case 8:
          text = '直播间发言时'
          break
        case 9:
          text = '绑定手机号'
          break
        case 10:
          text = '修改手机号'
          break
        case 11:
          text = '修改密码'
          break
        case 12:
          text = '登录PC网校'
          break
        case 13:
          text = '账号保护验证'
          break
        case 14:
          text = '登录H5网校'
          break
        case 15:
          text = '信息采集短信验证'
          break
        case 16:
          text = '推广员激活'
          break
        case 17:
          text = '个人微信商户绑定'
          break
      }
      return text
    },
  },

  methods: {
    sortchagne(val) {
      this.post3.sort = !val.order ? '' : val.order === 'ascending' ? 1 : 2
    },

    sortchagne12(val) {
      this.post5.sort = !val.order ? '' : val.order === 'ascending' ? 1 : 2
    },

    //普通直播的排序
    change3(value) {
      this.post1 = _.assign({}, this.post1, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order
          ? ''
          : value.prop == 's_time'
          ? 1
          : value.prop == 'm_time'
          ? 3
          : 2,
      })
    },

    // 录播回放和小班的排序
    change2(value) {
      this.post2 = _.assign({}, this.post2, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
      })
    },

    //极速直播的排序
    change(value) {
      this.post1 = _.assign({}, this.post1, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop == 'im_number' ? 1 : 2,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-range-editor.el-input__inner {
  width: 292px;
}

::v-deep .select3 .el-input--medium .el-input__inner {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

::v-deep .option {
  .el-input__inner {
    height: 36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

::v-deep .input {
  .el-input__inner {
    height: 36px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.font1 {
  color: #ff3535;
}

::v-deep .pagination {
  background-color: #fff;
}

.detail {
  padding: 20px;
}

.first_container {
  font-size: 12px;
  color: rgba(51, 51, 51, 1);
  line-height: 12px;
  padding: 20px;
  background-color: #fff;

  .standard {
    float: right;
    color: #1b9d97;
    cursor: pointer;
  }

  .el-row {
    margin-bottom: 23px;
  }

  .el-row:last-child {
    margin-bottom: 0;
  }
}

.tird_container {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;

  .el-input__inner {
    height: 36px;
    line-height: 36px;
  }

  .select {
    width: 110px;
    height: 30px;
    float: right;
  }

  .select2 {
    width: 160px;
    height: 30px;
    float: right;
    margin-right: 10px;
  }

  .input-with-select {
    width: 200px;
    float: right;
    margin-bottom: 20px;
  }
}
</style>
